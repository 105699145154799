<template>
  <div :class="{'top-btn': scroll_pos > 300, 'top-btn-invisible': scroll_pos <= 300}" @click="onMoveTop">
    <svgicon name="keyboard_arrow_up" width="30px" height="30px" />
  </div>
</template>

<script>
export default {
  name: 'TopBtn',
  data() {
    return {
      scroll_pos: 0
    }
  },
  created() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    onMoveTop() {
      let move_pos = Math.max(this.scroll_pos*0.1, 10);
      window.scrollTo(0, Math.max(this.scroll_pos-move_pos, 0));
      if (this.scroll_pos > 0) {
        setTimeout(this.onMoveTop, 10);
      }
    },
    updateScroll() {
      this.scroll_pos = window.scrollY;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style';
.top-btn {
  cursor: pointer;
  display: block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  padding: 10px;
  background-color: rgba($info, .8);
  border-radius: 3rem;
  animation: fadein-top-btn 1s;
  color: #eee;
}
.top-btn-invisible {
  cursor: pointer;
  display: block;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  padding: 10px;
  background-color: rgba($info, .8);
  border-radius: 3rem;
  animation: fadeout-top-btn 1s;
  color: #eee;
  opacity: 0;
  z-index:-1;
}
@keyframes fadein-top-btn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout-top-btn {
  from {
    opacity: 1;
    z-index:100;
  }
  to {
    opacity: 0;
    z-index:-1;
  }
}
</style>